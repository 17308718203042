import { defineStore } from 'pinia'
export const useDealsStore = defineStore('deals', {
  state: () => ({ 
    deal_:{
      title:'',
      start_date:null,
      description:'',
      employee:'',
      services:[],
      discount_type:'percent',
      discount:'',
      expire_date:null,
      package:true,
      status:1,
      business_id: 0,
      banner: ''
    },
    filters_:{
      render: 'business',
      business_type: null,
      sort_by: 'featured',
      currency_range: null,
      isOpen: false,
      hasDeals: false,
      rating: 0,
      gender: "",
      distance: 0,
      limit: 16,
    },
    deals:[],
    isLoading:true,
    load_more: {value:false,isLoading:false},
    page: 1
  }),
  actions: {
    async getDeals(params,isAppend=false) {
      isAppend ? this.isLoading = false : this.isLoading = true;
      let filters = {...this.filters_, ...params};
      filters.page = this.page
      const resp = await this.axios.get('deals',{params: filters})
      if (resp.data.status === 'success') {
        const data = resp.data.data.data
        if (isAppend) {
          this.deals = this.deals.concat(data)
        }else{
          this.deals = data
        }
        if (resp.data.data.next_page_url === null) {
          this.load_more.value = false
        }else{
          this.load_more.value = true
        }
      }
      this.isLoading = false
      this.load_more.isLoading = false
    },

    async addNewDeal() {
      const resp = await this.axios.post('add_new_deal',this.deal_).catch(error => {return error.response})
      return resp.data
    },
    async updateStatus(slug,status) {
      const newStatus = !status
      const resp = await this.axios.put('update_deal_status',{slug:slug,status:newStatus}).catch(error => {return error.response})
      return resp.data
    },
    async dealDetail(slug){
      const resp = await this.axios.get('deal_detail/'+slug).catch(error => {return error.response})
      return resp.data
    },
    async editDeal(slug){
      const resp = await this.axios.put('edit_deal/'+slug,this.deal_).catch(error => {return error.response})
      return resp.data
    },

    // Filters Work
    updateFilters(route,filters = null){
      if (filters === null) {
        for (const key in route.query) {
          this.filters_[key] = route.query[key]
        }
      }else{
        let query = {}
        filters.rating > 0 ? query.rating=filters.rating : ''
        filters.currency_range !== null  ? query.currency_range=filters.currency_range : ''
        filters.gender !== '' ? query.gender = filters.gender : ''
        filters.distance > 0 ? query.distance=filters.distance : ''
        this.router.push({ name: "AllDeals", query: query,params:route.params });
        this.getDeals(route.params)
      }
    },
    clearFilters(){
      const emptyFilters = {
        currency_range: null,
        rating: 0,
        gender: "",
        distance: 0,
      }
      this.filters_ = { ...this.filters_, ...emptyFilters };
    }
  }
})