<template>
    <AppContainer>
        <EditDeal/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import EditDeal from '@/components/dashboard/deals/EditDeal.vue'
export default {
  components: {
    AppContainer,
    EditDeal
  }
}
</script>